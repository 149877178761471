<template>
    <div>

        <a-card class="para-card" title="环境" bordered size="small">

            <control-para-item v-for="item in irriEnvParas"
                          :option="item"
                          :iotId="iotId"
                          :valueMap="valueMap"
                          :value="getValue(item)"
            ></control-para-item>

        </a-card>


    </div>
</template>

<script>
    import ParasUtil from "./ParasUtil"
    import ControlParaItem from "./ControlParaItem";
    export default {
        components:{ControlParaItem},
        props:["iotId","valueMap","paraName"],
        data(){
            return {

            }
        },
        computed: {
            irriEnvParas(){
                return this.parasWithZone(ParasUtil.irriEnvParas(),1)
            },
        },
        methods:{
            getValue(item){
                let value = this.valueMap[item.index+""] || 0
                let switchValue = this.valueMap[item.switchIndex+""] || 0

                if(item.valueFormatter){
                    return item.valueFormatter(switchValue,value)
                }else if(item.rate){
                    if(item.rate<100){
                        return (value/item.rate).toFixed(1)
                    }else{
                        return (value/item.rate).toFixed(2)
                    }
                }else{
                    return value
                }

            },

            parasWithZone(paras,zone){
                if(!zone){
                    zone = this.zone
                }
                paras.forEach(item=>{
                    if(item.index>0){
                        item.index = zone*256 + item.index
                    }
                    if(item.switchIndex>0){
                        item.switchIndex = zone*256 + item.switchIndex
                    }
                })
                return paras
            },

        }
    }
</script>

<style scoped lang="scss">
.para-card{
    margin-bottom: 20px;
    background: #F8F8F8;
    border-radius: 5px;
}

    .timing-container{
        border-radius: 10px;
        overflow: hidden;
        margin: 15px 5px;
        border: 1px solid #DDD
    }

</style>
