<template>
    <page-content title="设备详情">
        <a-descriptions :column="1" bordered size="small" style="margin-bottom: 20px">
            <a-descriptions-item label="产品">
                <a-avatar shape="square" :size="50" :src="device.devicePicture"
                          style="background: #F8F8F8;margin-right: 10px"/>
                {{device.productName}}

            </a-descriptions-item>
            <a-descriptions-item label="设备ID">{{device.iotId}}</a-descriptions-item>
            <a-descriptions-item label="设备MAC">{{device.mac}}</a-descriptions-item>
            <a-descriptions-item label="设备名称">{{device.nickname}}</a-descriptions-item>
            <a-descriptions-item label="设备地址">{{device.address}} &nbsp;&nbsp;
                <a-button @click="editDevice" type="primary" ghost size="small" icon="edit" />
            </a-descriptions-item>
            <a-descriptions-item label="上次连接IP">{{device.ip}}</a-descriptions-item>
        </a-descriptions>

        <control-plant v-if="isDevicePlant" :iotId="device.iotId" :valueMap="valueMap"></control-plant>
        <control-fish-tank v-else-if="isDeviceFishTank" :iotId="device.iotId" :valueMap="valueMap"></control-fish-tank>
        <control-environment v-else-if="idDeviceEnvironment" :iotId="device.iotId" :valueMap="valueMap" :paraName="paraNameMap"></control-environment>
        <control-irrigation v-else :iotId="device.iotId" :valueMap="valueMap" :paraName="paraNameMap"></control-irrigation>

        <dialog-edit-number ref="dialogNumber"></dialog-edit-number>
        <dialog-edit-time ref="dialogTime"></dialog-edit-time>
        <dialog-edit-date ref="dialogDate"></dialog-edit-date>
        <dialog-edit-select ref="dialogSelect"></dialog-edit-select>

        <DeviceEdit ref="deviceEdit" @success="getDeviceInfo"></DeviceEdit>
    </page-content>


</template>
<script>


    import ControlPlant from "./control/ControlPlant";
    import ControlFishTank from "./control/ControlFishTank";
    import ControlIrrigation from "./control/ControlIrrigation";
    import ControlEnvironment from "./control/ControlEnvironment";

    import ParasUtil from "./control/ParasUtil"
    import DialogEditNumber from "./control/dialog/dialog-edit-number"
    import DialogEditDate from "./control/dialog/dialog-edit-date"
    import DialogEditSelect from "./control/dialog/dialog-edit-select"
    import DialogEditTime from "./control/dialog/dialog-edit-time"

    import DeviceEdit from "./DeviceEdit";

    export default {
        name: "DeviceDetail",
        components: {
            DeviceEdit,

            ControlPlant,
            ControlFishTank,
            ControlIrrigation,
            ControlEnvironment,

            DialogEditNumber,
            DialogEditDate,
            DialogEditSelect,
            DialogEditTime
        },
        data() {
            return {
                getDataInterval: null,
                device: {
                    name: "",
                    iotId: "",
                    mac: "",
                    activeTime: "",
                    status: null,
                    firmwareVersion: ""
                },
                valueMap: {},
                paraNameMap:{},
                userInfo: {
                    loading: false,
                    dataSource: []
                },

                tabList: [
                    {
                        key: "DataMonitor",
                        tab: "数据监控"
                    },
                    {
                        key: "AlarmRecord",
                        tab: "报警记录"
                    },
                    {
                        key: 'UpDownStream',
                        tab: '上下行消息'
                    },

                ],
                tabKey: "DataMonitor",
            };
        },
        computed: {
            isDevicePlant() {
                return this.device.productKey == "PLANT_BOX" ||
                    this.device.productKey == "PLANT_FRAME"
            },
            idDeviceEnvironment(){
                return this.device.productKey == "ENVIRONMENT_MONITOR"
            },
            isDeviceFishTank(){
                return this.device.productKey == "FISH_TANK"
            },
            userInfoColumns() {
                return [
                    {
                        title: '用户ID',
                        dataIndex: 'identityId'
                    },
                    {
                        title: '用户名',
                        dataIndex: 'nickName'
                    },
                    {
                        title: '手机号',
                        dataIndex: 'phone'
                    },
                    {
                        title: '邮箱',
                        dataIndex: 'email'
                    },
                    {
                        title: '用户类型',
                        dataIndex: 'owned',
                        scopedSlots: {customRender: "own"},
                        width: 100
                    }
                ]
            }
        },
        created() {
            let iotId = this.$route.params.iotId
            this.device.iotId = iotId
            this.getDeviceInfo()
            //获取绑定了设备的用户
            this.$get(`device/${iotId}/users`, {}).then(r => {
                this.userInfo.dataSource = r.data;
            });

            //获取设备参数
            this.getDeviceData()
            this.getDataInterval = setInterval(() => {
                this.getDeviceData()
            }, 3000)

            //获取参数名称设置
            this.getDeviceParaName()

            setTimeout(() => {
                ParasUtil.iotId = this.device.iotId
                ParasUtil.dialogNumber = this.$refs.dialogNumber
                ParasUtil.dialogTime = this.$refs.dialogTime
                ParasUtil.dialogDate = this.$refs.dialogDate
                ParasUtil.dialogSelect = this.$refs.dialogSelect

                ParasUtil.controlFunction = this.controlDevice

            }, 2000)
        },

        destroyed() {
            clearInterval(this.getDataInterval)
        },

        methods: {

            editDevice(){
                this.$refs.deviceEdit.show(this.device)
            },

            getDeviceInfo(){
                // 获取设备详细信息
                this.$get(`device/${this.device.iotId}`, {}).then(r => {
                    this.device = r.data;
                });
            },

            getDeviceData(){
                this.$get(`device/data/${this.device.iotId}`, {}).then(r => {
                    let map = {}
                    r.data.forEach(item => {
                        map[item.index + ""] = item.value
                    })
                    this.valueMap = {
                        ...this.valueMap,
                        ...map
                    }
                });
            },

            getDeviceParaName(){
                this.$get(`device/paraname/${this.device.iotId}`, {}).then(r => {
                    this.paraNameMap = r.data
                });
            },

            controlDevice(items) {

                const hide = this.$message.loading('正在设置...', 0);

                let key = Object.keys(items)[0]
                let value = items[key]


                this.$post("device/control", {
                    iotId: this.device.iotId,
                    dataIndex: key,
                    value: value
                }).then(suc => {
                    this.valueMap = {
                        ...this.valueMap,
                        ...items
                    }

                    hide()
                }).catch(err => {
                    hide()
                })
            },
        }
    };
</script>
<style lang="less" scoped>
    .device-info {
        &-content {
            display: flex;
            padding-top: 10px;

            p {
                font-size: 12px;
                color: #999;
                margin-bottom: 10px;
            }

            &-left {
                margin-left: 16px;
            }

            &-right {
                margin-left: 26px;
            }
        }
    }

    .deviceDetail {
        .user-info {
            margin: 24px 0;

            .ant-card-head {
                border-bottom: none;
            }
        }

        .user-info-table,
        .dataMonitor-table,
        .errorEvent-table {
            .ant-table-thead > tr > th {
                color: #999;
                padding: 6px 16px;
            }

            .ant-card-body {
                padding: 0;
            }
        }
    }
</style>
